import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Store } from '@ngrx/store';
import { User } from '../models/user';
import { AppStore } from '../store/app-store';
import { Observable } from 'rxjs';
import { APPCONFIG } from '../config';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

    public AppConfig: any;
    displayName: any;
    user: Observable<User>;
    textIntro: string;

    constructor(
        public authService: AuthService,
        private store: Store<AppStore>
    ) {
        this.user = this.store.select('user');
        this.textIntro = "Bienvenue dans votre interface<br>d'évaluation des compétences<br>de vos collaborateurs";
    }

    ngOnInit() {
        this.AppConfig = APPCONFIG;

        if (this.authService.authenticated) {
            this.authService.getCurrentUserDisplayName().subscribe( (data) => this.displayName = data.displayName );
        }
    }

    logout() {
        this.authService.signOut();
    }

    isLoggedIn() {
        return this.authService.authenticated;
    }
}
