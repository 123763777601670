import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'dialog-confirmrelance-dialog',
    template: `
        <div mat-dialog-content="">
            <i class="material-icons dialog-grande-icone">assignment_turned_in</i>
            <div>
                <p><strong>Êtes-vous sûr.e</strong><br>
                    de relancer par mail les membres concerné.e.s par ce bilan&nbsp;?</p>
            </div>
        </div>
        <div md-dialog-actions>
            <button mat-button (click)="dialogRef.close('validate')">Confirmer la relance par mail</button>
            <button mat-button mat-dialog-close>Annuler</button>
        </div>
    `,
})
export class DialogConfirmerelanceDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<DialogConfirmerelanceDialogComponent>
    ) {}
}
