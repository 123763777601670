import { Injectable } from '@angular/core';
import { Profil } from '../models';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Configuration } from '../config';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class ProfilsService {

    private actionUrl: string;
    private actionUrl2: string;
    private registeredUrl: string;
    selectedItem: Profil = new Profil();
    clientActuel = 49; // Client test
    usertoken: string;

    constructor(private http: HttpClient, private _configuration: Configuration, public authService: AuthService) {
        this.actionUrl = _configuration.ServerWithApiUrl + 'client/' + this.clientActuel + '/profil/';
        // this.registeredUrl = _configuration.ServerWithApiUrl + 'client/' + this.clientActuel + '/profilfree/';
        this.registeredUrl = _configuration.ServerWithApiUrl + 'profilfree/';
        this.usertoken = authService.currentUser.pa;
        console.log('User token :', this.usertoken);
        localStorage.setItem('token', this.usertoken);

        this.actionUrl2 = _configuration.ServerWithApiUrl + 'profil/';
    }

    public getAll<T>(): Observable<T> {
        return this.http.get<T>(this.actionUrl2);
    }

    public getSingle<T>(id: number): Observable<T> {
        return this.http.get<T>(this.actionUrl2 + id);
    }

    public add<T>(itemName: Profil): Observable<T> {
        const toAdd = JSON.stringify(itemName);

        return this.http.put<T>(this.actionUrl2, toAdd);
    }

    public update<T>(id: number, itemToUpdate: any): Observable<T> {
        return this.http
            .post<T>(this.actionUrl2 + id, JSON.stringify(itemToUpdate));
    }

    public delete<T>(id: number): Observable<T> {
        return this.http.delete<T>(this.actionUrl2 + id);
    }

    public isProfiltitleFree<T>(title: string): Observable<T> {
        return this.http
            .post<T>(this.registeredUrl, JSON.stringify({ titre: title}));
    }

    public getToken<T>() {
        return this.usertoken;
    }
}
