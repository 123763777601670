export class QuestionnaireReponse {
    step: string;
    q11: string;
    q12: string;
    q21: string;
    q22: string;
    q31: string;
    q32: string;
    q41: string;
    q42: string;
    q51: string;
    q52: string;
    exprimez: string;
    constructor(step: string) {
        this.step = step;
        this.q11 = '';
        this.q12 = '';
        this.q21 = '';
        this.q22 = '';
        this.q31 = '';
        this.q32 = '';
        this.q41 = '';
        this.q42 = '';
        this.q51 = '';
        this.q52 = '';
        this.exprimez = '';
    }
}

export class Questionnaire {
    slug: string;
    id: number;
    title: string;
    step: number;
    bilan: number;
    evaluateurId: number;
    evaluateur: string;
    candidatId: number;
    candidat: string;
    profilId: number;
    prodil: string;
    logo: string;
    etat: string;
    nbquestions: number;
    competences: [
        {
            id: number;
            titre: string;
            definition: string;
            actions: [
                {
                    id: number;
                    description: string;
                }
                ]
        }
    ];
    reponses: QuestionnaireReponse[];
    constructor() {
        this.logo = '/assets/images/1pixel.png';
        this.reponses = new Array() as QuestionnaireReponse[];
        for (let i = 0 ; i < 5 ; i++) {
            const q = new QuestionnaireReponse(String(i + 1));
            this.reponses.push(q);
        }
    }
}
