import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BilansRoutingModule } from './bilans-routing.module';
import { BilansComponent } from './bilans.component';
import { BilanAddComponent } from './bilan-add/bilan-add.component';
import { BilanService } from '../services/bilan.service';
import { RapportService } from '../services/rapport.service';
import { FormsModule } from '@angular/forms';
import {
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatProgressBarModule,
    MatStepperModule,
    MatProgressSpinnerModule
} from '@angular/material';
import { DynamicFormsCoreModule } from '@ng-dynamic-forms/core';
import { DynamicFormsMaterialUIModule } from '@ng-dynamic-forms/ui-material';
import { BilanDetailComponent } from './bilan-detail/bilan-detail.component';
import { BilanEditComponent } from './bilan-edit/bilan-edit.component';
import { TinymceModule } from 'angular2-tinymce';
import { PersonnelService } from '../services/personnel.service';
import { ProfilsService } from '../services/profils.service';

@NgModule({
  imports: [
    CommonModule,
    BilansRoutingModule,
      FormsModule,
      MatInputModule,
      MatButtonModule,
      MatDatepickerModule,
      MatSelectModule,
      MatRadioModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      DynamicFormsCoreModule.forRoot(),
      DynamicFormsMaterialUIModule,
      TinymceModule.withConfig({}),
      MatStepperModule,
  ],
  declarations: [
      BilansComponent,
      BilanAddComponent,
      BilanDetailComponent,
      BilanEditComponent
  ],
  exports: [
      BilanEditComponent
  ],
  providers: [
      BilanService,
      RapportService,
      PersonnelService,
      ProfilsService
  ]})
export class BilansModule { }
