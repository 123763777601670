import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QuestionnaireComponent } from './questionnaire.component';
import { QuestionnaireService } from '../services/questionnaire.service';
import { MatButtonModule, MatInputModule, MatRadioModule, MatStepperModule, MatDialogModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { DialogClosedDialogComponent } from './dialog-closed.component';
import { DialogThanksDialogComponent } from './dialog-thanks.component';
import { DialogValidateDialogComponent } from './dialog-validate.component';

@NgModule({
    imports: [
      CommonModule,
        MatStepperModule,
        MatButtonModule,
        MatRadioModule,
        MatInputModule,
        MatDialogModule,
        FormsModule,
        LayoutModule
    ],
    declarations: [
      QuestionnaireComponent,
      DialogClosedDialogComponent,
      DialogThanksDialogComponent,
      DialogValidateDialogComponent
    ],
    entryComponents: [
      DialogClosedDialogComponent,
      DialogThanksDialogComponent,
      DialogValidateDialogComponent
    ],
    exports: [
    ],
    providers: [
        QuestionnaireService
    ]
})
export class QuestionnaireModule { }
