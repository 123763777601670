import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { Store } from '@ngrx/store';
import { AppStore } from '../store/app-store';

@Component({
  selector: 'app-aide',
  templateUrl: './aide.component.html',
  styleUrls: ['./aide.component.scss']
})
export class AideComponent implements OnInit {
    user: Observable<User>;

    constructor(
        private store: Store<AppStore>
    ) {
        this.user = this.store.select('user');
    }

  ngOnInit() {
  }

}
