import { Component, OnInit } from '@angular/core';
import { Client } from '../../models';
import { ClientService } from '../../services/client.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit {

  clientListe: Client[];

  constructor(private clientService: ClientService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
      this.clientService
          .getAll<any[]>()
          .subscribe((data: Client[]) => this.clientListe = data,
              (error) => () => {
                  console.log('Erreur récupération clients');
              },
              () => {
              });
  }

  onItemClick(client: Client) {
      this.clientService.selectedClient = Object.assign({}, client);
  }

  goToNewClient() {
      this.clientService.selectedClient = new Client();
  }
}
