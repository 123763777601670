import { Component, OnInit } from '@angular/core';
import { Bilan } from '../models';
import { BilanService } from '../services/bilan.service';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { User } from '../models/user';
import { AppStore } from '../store/app-store';
import { RapportService } from '../services/rapport.service';
import { saveAs } from 'file-saver/FileSaver';
import { Observable } from 'rxjs';
import { DialogConfirmerelanceDialogComponent } from './dialog-confirmrelance.component';
import { DialogConfirmdeleteDialogComponent } from './dialog-confirmdelete.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  bilanList: Bilan[];
  resultatRelance: {};
  resultatDelete: {};
  user: Observable<User>;
  isAdmin: boolean = false;

  constructor(
      private itemService: BilanService,
      private rapportService: RapportService,
      public dialog: MatDialog,
      private store: Store<AppStore>
  ) {
      this.user = this.store.select('user');

      this.user.subscribe( (data) => this.isAdmin = (data.role === 'Admin') );
  }

    ngOnInit() {
      this.itemService
          .getAll<any[]>()
          .subscribe((data: Bilan[]) => this.bilanList = data,
              (error) => () => {
                  console.log('Erreur récupération bilans');
              });
  }

  relanceItem(item: Bilan) {
      const dialogRef = this.dialog.open(DialogConfirmerelanceDialogComponent);
      dialogRef.afterClosed().subscribe((result) => {
          if (result === 'validate') {
              console.log('Relance :', item.id);
              this.itemService.relance(item.id)
                  .subscribe((data) => this.resultatRelance = data,
                      (error) => () => {
                          console.log('Erreur relance bilan', item);
                      });
          }
      });
  }

  viewItem(item: Bilan) {
      this.itemService.selectedItem = Object.assign({}, item);
  }

  deleteItem(id: number) {
      const dialogRef = this.dialog.open(DialogConfirmdeleteDialogComponent);
      dialogRef.afterClosed().subscribe((result) => {
          if (result === 'validate') {
              console.log('Suppression bilan :', id);
              this.itemService.delete(id)
                  .subscribe((data) => {
                          this.resultatDelete = data;
                          this.ngOnInit();
                      },
                          (error) => () => {
                              console.log('Erreur suppression bilan', id);
                          }
                  );
          }
      });
  }

  editItem(item: Bilan) {
    this.itemService.selectedItem = Object.assign({}, item);
  }

  recupRapport(item: Bilan) {
      console.log('Rapport :', item.id);
      this.rapportService.getSingle(item.id)
          .subscribe(
              (data) => {
                  this.downloadFile(data);
                  // console.log('Download PDF data', data);
              },
              (error) => () => {
                console.log('Erreur download rapport', item);
              },
              () => {
                console.info('Download rapport OK');
              }
          );
  }

    downloadFile(data: any) {
      const pdf = atob(data.blob);
      const array = new Uint8Array(pdf.length);

      for ( let i = 0; i < pdf.length; i++ ) {
        array[i] = pdf.charCodeAt(i);
      }

      const blob = new Blob([array], { type: 'application/pdf' });

      // 1re option : Sauvegarde directe d'un fichier
      const filename = 'Bilan' + data.id + '.pdf';
      saveAs(blob, filename);

      // 2e option : Ouverture d'un onglet avec le fichier PDF inline
      // const url = window.URL.createObjectURL(blob);
      // window.open(url);
    }
}
