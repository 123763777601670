import { DynamicFormArrayModel, DynamicFormControlModel, DynamicInputModel, DynamicRadioGroupModel } from '@ng-dynamic-forms/core';

export const BILANS_FORM_MODEL: DynamicFormControlModel[] = [

    new DynamicFormArrayModel({
        id: 'bilanEvalFormArray',
        initialCount: 5,
        groupFactory: () => {
            return [
                new DynamicInputModel({
                    id: 'myInput',
                    label: 'My Input'
                }),
                new DynamicRadioGroupModel<string>({

                    id: 'sampleRadioGroup',
                    label: 'Sample Radio Group',
                    options: [
                        {
                            label: 'Option 1',
                            value: 'option-1'
                        },
                        {
                            label: 'Option 2',
                            value: 'Option-2'
                        },
                        {
                            label: 'Option 3',
                            value: 'option-3'
                        }
                    ],
                    value: 'option-3'
                }),
            ];
        }
    })
];
