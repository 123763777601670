import { RouterModule, Routes } from '@angular/router';

import { LoginPageComponent } from './login-page/login-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { AlwaysAuthGuard } from './guards/alwaysauthguard';
import { LostPasswordComponent } from './lost-password/lost-password.component';
import { QuestionnaireComponent } from './questionnaire/questionnaire.component';
import { LayoutComponent } from './layout/layout.component';
import { Page404Component } from './extra-pages/404/404.component';
import { WelcomeComponent } from './welcome/welcome.component';

const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginPageComponent
    },
    {
        path: 'lost-password',
        component: LostPasswordComponent
    },
    // {
    //     path: 'competences',
    //     component: CompetencesListComponent,
    //     canActivate: [AlwaysAuthGuard]
    // },
    // {
    //     path: 'clients',
    //     loadChildren: 'app/clients/clients.module#ClientsModule',
    //     canActivate: [AlwaysAuthGuard]
    // },
    // // {
    // //     path: 'personnels',
    // //     loadChildren: 'app/personnels/personnels.module#PersonnelsModule',
    // //     canActivate: [AlwaysAuthGuard]
    // // },
    // // {
    // //     path: 'profils',
    // //     loadChildren: 'app/profils/profils.module#ProfilsModule',
    // //     canActivate: [AlwaysAuthGuard]
    // // },
    // {
    //     path: 'bilans',
    //     loadChildren: 'app/bilans/bilans.module#BilansModule',
    //     canActivate: [AlwaysAuthGuard]
    // },
    {
        path: 'questionnaire/:key',
        component: QuestionnaireComponent
    },
    {
        path: 'home',
        component: HomePageComponent
    },
    {
        path: 'welcome',
        component: WelcomeComponent,
        canActivate: [AlwaysAuthGuard]
    },
    {
        path: 'app',
        component: LayoutComponent,
        canActivate: [AlwaysAuthGuard]
    },
    {
        path: '**',
        component: Page404Component
    },
];

export const AppRoutingModule = RouterModule.forRoot(AppRoutes, /*{ enableTracing: true }*/);
