import { Component, OnInit } from '@angular/core';
import { Bilan } from '../../models';
import { BilanService } from '../../services/bilan.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-bilan-edit',
  templateUrl: './bilan-edit.component.html',
  styleUrls: ['./bilan-edit.component.scss']
})
export class BilanEditComponent implements OnInit {
    item: Bilan;
    spinner: boolean = false;

  constructor(
      public itemService: BilanService,
      private _location: Location,
      private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
      this.route.params.subscribe((params: any) => {
          if (params.key) {
              const key = this.route.snapshot.paramMap.get('key');
              this.itemService.getSingle(Number(key)).subscribe( (item) => {
                  this.itemService.selectedItem = item as Bilan;
              });
          }
      });
  }

    saveBilan() {
      this.spinner = true;
      this.itemService.update(this.itemService.selectedItem.id, this.itemService.selectedItem).subscribe( () => this.spinner = false );
    }
}
