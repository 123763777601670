import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormArray, FormGroup, NgForm } from '@angular/forms';
import { BilanService } from '../../services/bilan.service';
import { PersonnelService } from '../../services/personnel.service';
import { Personnel, Profil } from '../../models';
import { ProfilsService } from '../../services/profils.service';
import { DynamicFormArrayModel, DynamicFormControlModel, DynamicFormService } from '@ng-dynamic-forms/core';
import { BILANS_FORM_MODEL } from '../bilans.form.model';

@Component({
  selector: 'app-bilan-add',
  templateUrl: './bilan-add.component.html',
  styleUrls: ['./bilan-add.component.scss']
})
export class BilanAddComponent implements OnInit {

  listeProfils: Profil[];
  listePersonnels: Personnel[];
  formModel: DynamicFormControlModel[] = BILANS_FORM_MODEL;
  formGroup: FormGroup;
  nbEvaluateurs: number;

  formArrayControl: FormArray;
  formArrayModel: DynamicFormArrayModel;

  constructor(
      public bilanService: BilanService,
      public personnelService: PersonnelService,
      public profilService: ProfilsService,
      private route: ActivatedRoute,
      private router: Router,
      private formService: DynamicFormService,
  ) { }

    ngOnInit() {
        this.bilanService.selectedItem.evaluateurs = new Array();
        for (let i = 0; i < 5 ; i++) {
            this.bilanService.selectedItem.evaluateurs.push({ personnel: 0, niveau: 'egal' });
        }
        this.nbEvaluateurs = 5;
        this.resetForm();
        this.route.params.subscribe((params: any) => {
            if (params.key) {
                const key = parseInt(this.route.snapshot.paramMap.get('key'), 10);
                this.personnelService.getSingle(key).subscribe( (x: Personnel) => { this.personnelService.selectedItem = x; });
                this.profilService.getAll().subscribe((x: Profil[]) => { this.listeProfils = x; } );
                this.personnelService.getAll().subscribe( (x: Personnel[]) => {this.listePersonnels = x; } );
                this.bilanService.selectedItem.personnel = key;
            }
        });

        this.formGroup = this.formService.createFormGroup(this.formModel);
        // this.formArrayControl = this.formGroup.get('bilanEvalFormArray') as FormArray;
        // this.formArrayModel = this.formService.findById('bilanEvalFormArray', this.formModel) as DynamicFormArrayModel;

    }

    onSubmit(form: NgForm) {
        if (form.value.id == null) {
            this.bilanService.selectedItem.datedebutnumber = Number(this.bilanService.selectedItem.datedebut);
            this.bilanService.selectedItem.datefinnumber = Number(this.bilanService.selectedItem.datefin);
            this.bilanService.add(this.bilanService.selectedItem).subscribe( (x) => { this.router.navigateByUrl('/app/personnels/list'); } );
        } else {
            this.bilanService.update(this.bilanService.selectedItem.id, this.bilanService.selectedItem).subscribe( (x) => { this.router.navigateByUrl('/app/personnels/list'); } );
        }
    }

    resetForm(form?: NgForm) {
        if (form != null) {
            form.reset();
        }
    }

    onDelete(form: NgForm ) {
        if (confirm('Are you sure to delete this record ?') === true) {
            // this.personnelService.deletePersonnel(form.value.$key);
            this.router.navigateByUrl('/app/personnels/list');
        }
    }

    addEvaluateur() {
      this.bilanService.selectedItem.evaluateurs.push({ personnel: 0, niveau: 'egal' });
      this.nbEvaluateurs++;
    }

    bilan() {
      console.log('Bilan', this.bilanService.selectedItem);
    }

}
