export class BilanReponse {
    id: string;
    idEvaluateur: number;
    nom: string;
    prenom; string;
    progression: number;
    step: string;
}

export class Bilan {
    id: number;
    client: number;
    clientname: string;
    personnel: number;
    profil: number;
    profilname: string;
    datedebut: string;
    datedebutfr: string;
    datedebutnumber: number;
    datefin: string;
    datefinfr: string;
    datefinnumber: number;
    valide: boolean;
    evaluateurs: {
        personnel: number;
        niveau: string;
    }[];
    nom: string;
    prenom: string;
    progression: number;
    reponses: BilanReponse[];
    commentaires: {
        competence: string;
        commentaires: string[];
    };
    verbatim: string[];
}
