import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.scss']
})
export class LostPasswordComponent implements OnInit {
    resetForm: FormGroup;
    // detailForm: FormGroup;

    constructor(public fb: FormBuilder, public auth: AuthService, private router: Router) { }
    ngOnInit() {
        // // First Step
        this.resetForm = this.fb.group({
            email: ['', [
                Validators.required,
                Validators.email
            ]
            ],
        });
    }

    get email() { return this.resetForm.get('email'); }

    resetPassword() {
        this.auth.resetPassword(this.email.value)
            .then((data) => {
                alert('Email envoyé');
            })
            .catch((error) => {
                console.log( 'reset password failed :' + error);
            });
    }
}
