import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';




@Injectable()
export class AlwaysAuthGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | boolean {
        return this.authService.currentUserObservable
            .take(1)
            .map( (user) => !!(user))
            .do( (loggedIn) => {
                if (!loggedIn) {
                    this.authService.redirectUrl = state.url;
                    this.router.navigate(['/login']);
                }
            });
    }
}
