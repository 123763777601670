import { Component, OnInit } from '@angular/core';
import { Bilan } from '../models';
import { BilanService } from '../services/bilan.service';

@Component({
  selector: 'app-bilans',
  templateUrl: './bilans.component.html',
  styleUrls: ['./bilans.component.scss']
})
export class BilansComponent implements OnInit {

  constructor(
      private itemService: BilanService
  ) { }

  ngOnInit() {
  }

    onItemClick(item: Bilan) {
        this.itemService.selectedItem = Object.assign({}, item);
    }

}
