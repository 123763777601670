import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'dialog-validateprofil-dialog',
    template: `
        <div mat-dialog-content="">
            <i class="material-icons dialog-grande-icone">assignment_turned_in</i>
            <div>
                <p>
                    <strong>Vous avez finalisé le profil<br>
                        {{data.name}}.</strong><br>
                    La validation ne permet<br>
                    plus la modification du profil</p>
            </div>
        </div>
        <div md-dialog-actions>
            <button mat-button (click)="dialogRef.close('validate')">Valider le profil</button>
            <button mat-button mat-dialog-close>Annuler</button>
        </div>
    `,
})
export class DialogValidateProfilDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { name: string },
        public dialogRef: MatDialogRef<DialogValidateProfilDialogComponent>
    ) {}
}
