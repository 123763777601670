import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import { User } from '../models/user';
import { Store } from '@ngrx/store';
import * as UserActions from '../store/actions/user-actions';
import { AppStore } from '../store/app-store';
import { Observable } from 'rxjs';
import { Configuration } from '../config';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {

    authState: any = null;
    profileUrl: string = '';
    usertoken: string = '';
    displayName: string = '';
    public redirectUrl: string = ''; // Here is where the requested url is stored

    constructor(private store: Store<AppStore>,
                public afAuth: AngularFireAuth,
                private db: AngularFireDatabase,
                private router: Router,
                private _configuration: Configuration,
                private http: HttpClient
    ) {
        this.profileUrl = _configuration.ServerWithApiUrl + 'userprofile';

        this.afAuth.authState.subscribe((auth) => {
            console.log('AfAuth Authstate change');
            this.authState = auth;
            if (auth) {
                // user logged in
                console.log('Auth', auth);
                auth.getIdToken().then( (token) => {
                    this.usertoken = token;
                    console.log('User token :', this.usertoken);
                    localStorage.setItem('token', this.usertoken);
                    const newuser = ({
                        uid: auth.uid,
                        displayName: auth.displayName,
                        email: auth.email,
                        token: this.usertoken
                    }) as User;
                    this.store.dispatch(new UserActions.LoginSuccess(newuser) );

                    this.getCurrentUserDisplayName().subscribe( (x) => {
                        newuser.displayName = x.displayName;
                        newuser.societeId = x.client_id;
                        newuser.societe = x.societe;
                        if (x.logo) {
                            newuser.logo = x.logo;
                        } else {
                            newuser.logo = '/assets/images/1pixel.png';
                        }
                        newuser.role = x.role;

                        console.log('New User Dispatch', newuser);
                        this.store.dispatch(new UserActions.LoginSuccess(newuser) );
                    } );

                    this.redirect();
                } );

            } else {
                // user not logged in
                this.store.dispatch(new UserActions.Logoff(null));
            }
        });
    }

    // Returns true if user is logged in
    get authenticated(): boolean {
        return this.authState !== null;
    }

    // Returns current user data
    get currentUser(): any {
        return this.authenticated ? this.authState : null;
    }

    // Returns
    get currentUserObservable(): any {
        return this.afAuth.authState;
    }

    // Returns current user UID
    get currentUserId(): string {
        return this.authenticated ? this.authState.uid : '';
    }

    // Anonymous User
    get currentUserAnonymous(): boolean {
        return this.authenticated ? this.authState.isAnonymous : false;
    }

    // Returns current user display name or Guest
    getCurrentUserDisplayName(): any {
        return this.http.get(this.profileUrl);
    }

    get currentUserDisplayName(): string {
        if (!this.authState) {
            return 'Guest';
        } else if (this.currentUserAnonymous) {
            return 'Anonymous';
        } else {
            console.log('displayName', this.displayName);
            return this.authState['displayName'] || this.displayName || 'User without a Name';
        }
    }

    get currentUserRoles(): Observable<{}> {
        const path = `users/${this.currentUserId}/roles`; // Endpoint on firebase

        return this.db.object(path).valueChanges();
    }

    getUserRoles(user: User): Observable<User> {
        const path = `users/${this.currentUserId}/roles`; // Endpoint on firebase

        return this.db.object(path).valueChanges()
            .take(1)
            .map( (roles: string[]) => {
                user.roles = roles;
                return user;
            });
    }

    getUserProfile<T>(): Observable<T> {
        return this.http.get<T>(this.profileUrl);
    }

    //// Social Auth ////

    googleLogin() {
        const provider = new firebase.auth.GoogleAuthProvider();
        return this.socialSignIn(provider);
    }

    facebookLogin() {
        const provider = new firebase.auth.FacebookAuthProvider();
        return this.socialSignIn(provider);
    }

    twitterLogin() {
        const provider = new firebase.auth.TwitterAuthProvider();
        return this.socialSignIn(provider);
    }

    private socialSignIn(provider) {
        return this.afAuth.auth.signInWithPopup(provider)
            .then((credential) =>  {
                this.authState = credential.user;
//                this.updateUserData();
            })
            .catch((error) => console.log(error));
    }

    //// Anonymous Auth ////

    anonymousLogin() {
        return this.afAuth.auth.signInAnonymously()
            .then((user) => {
                this.authState = user;
//                this.updateUserData();
            })
            .catch((error) => console.log(error));
    }

    //// Email/Password Auth ////

    emailSignUp(email: string, password: string) {
        return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
            .then((user) => {
                this.authState = user.user;
                this.updateUserData();
            })
            .catch((error) => console.log(error));
    }

    emailLogin(email: string, password: string) {
        console.log('email login :', email);
        return this.afAuth.auth.signInWithEmailAndPassword(email, password);
    }

    // Sends email allowing user to reset password
    resetPassword(email: string) {
        const auth = firebase.auth();

        return auth.sendPasswordResetEmail(email)
            .then(() => console.log('email sent'))
            .catch((error) => console.log(error));
    }

    //// Sign Out ////

    signOut(): void {
        this.afAuth.auth.signOut();
        this.authState = null;
        this.router.navigate(['/home']);
        localStorage.clear();
    }

    //// Helpers ////

    private updateUserData(): void {
        // Writes user name and email to realtime db
        // useful if your app displays information about users or for admin features

        const path = `users/${this.currentUserId}`; // Endpoint on firebase
        const data = {
            email: this.authState.email,
            name: this.authState.displayName
        };

        this.db.object(path).update(data)
            .catch((error) => console.log(error));
    }

    private redirect(): void {
        if (this.redirectUrl.length) {
            this.router.navigate([ this.redirectUrl ]); // use the stored url here
        }
    }
}
