import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'dialog-confirmdelete-dialog',
    template: `
        <div mat-dialog-content="">
            <i class="material-icons dialog-grande-icone">warning</i>
            <div>
                <p><strong>Êtes-vous sûr.e</strong><br>
                    de vouloir supprimer ce bilan&nbsp;?</p>
            </div>
        </div>
        <div md-dialog-actions>
            <button mat-button (click)="dialogRef.close('validate')">Confirmer la suppression</button>
            <button mat-button mat-dialog-close>Annuler</button>
        </div>
    `,
})
export class DialogConfirmdeleteDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<DialogConfirmdeleteDialogComponent>
    ) {}
}
