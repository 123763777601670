import { Injectable } from '@angular/core';
import { Rapport } from '../models';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../config';
import { Observable } from 'rxjs';

@Injectable()
export class RapportService {

    private actionUrl: string;
    selectedItem: Rapport = new Rapport();

    constructor(private http: HttpClient, public _configuration: Configuration) {
        this.actionUrl = _configuration.ServerWithApiUrl + 'rapport/';
    }

    public getSingle<T>(id: number): Observable<T> {
        return this.http.get<T>(this.actionUrl + id);
    }

}
