import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Competence } from '../models';

@Component({
  selector: 'app-competences-list',
  templateUrl: './competences-list.component.html',
  styleUrls: ['./competences-list.component.css']
})
export class CompetencesListComponent implements OnInit {
    results: Competence[];

    constructor(private http: HttpClient) {}

    ngOnInit(): void {
        this.http.get('https://rh360api.netvolution.fr/wp-json/rh360/v1/competences').subscribe((data) => {
            this.results = data as Competence[];
        });
    }
}
