import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

// openDialogBefore
@Component({
    selector: 'dialog-thanks-dialog',
    template: `
        <i class="material-icons dialog-grande-icone">sentiment_very_satisfied</i>
        <div md-dialog-content class="dialog-grand-texte">Merci !</div>
    `,
})
export class DialogThanksDialogComponent {
    constructor(public dialogRef: MatDialogRef<DialogThanksDialogComponent>) {}
}
