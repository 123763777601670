import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BilansComponent } from './bilans.component';
import { BilanAddComponent } from './bilan-add/bilan-add.component';
import { BilanDetailComponent } from './bilan-detail/bilan-detail.component';
import { BilanEditComponent } from './bilan-edit/bilan-edit.component';

const routes: Routes = [
    {
        path: '',
        component: BilansComponent,
        children: [
            { path: 'add/:key', component: BilanAddComponent },
            { path: 'detail/:key', component: BilanDetailComponent },
            { path: 'edit/:key', component: BilanEditComponent },
        ]
    },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BilansRoutingModule { }
