import { Action } from '@ngrx/store';
import { User } from '../../models/user';

export const LOGIN_SUCCESS    = '[User] Login';
export const LOGOFF           = '[User] Logoff';
export const SET_DISPLAY_NAME = '[User] Set Display Name';

export class LoginSuccess implements Action {
    readonly type = LOGIN_SUCCESS;

    /// user a constructor to send a payload with the action
    constructor(public payload: User) {}
}

export class Logoff implements Action {
    readonly type = LOGOFF;

    /// user a constructor to send a payload with the action
    constructor(public payload: null) {}
}

export class SetDisplayName implements Action {
    readonly type = SET_DISPLAY_NAME;

    /// user a constructor to send a payload with the action
    constructor(public payload: User) {}
}

export type All
    = LoginSuccess
    | Logoff
    | SetDisplayName;
