import { Injectable } from '@angular/core';
import { Personnel } from '../models';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Configuration } from '../config';
import { Observable } from 'rxjs';

@Injectable()
export class PersonnelService {

    private actionUrl: string;
    private actionUrl2: string;
    selectedItem: Personnel = new Personnel();
    clientActuel = 49; // Client test

    constructor(private http: HttpClient, private _configuration: Configuration) {
        this.actionUrl = _configuration.ServerWithApiUrl + 'client/' + this.clientActuel + '/personnel/';
        this.actionUrl2 = _configuration.ServerWithApiUrl + 'personnel/';
    }

    public getAll<T>(): Observable<T> {
        return this.http.get<T>(this.actionUrl2);
    }

    public getSingle<T>(id: number): Observable<T> {
        return this.http.get<T>(this.actionUrl2 + id);
    }

    public add<T>(itemName: Personnel): Observable<T> {
        const toAdd = JSON.stringify(itemName);

        return this.http.put<T>(this.actionUrl2, toAdd);
    }

    public update<T>(id: number, itemToUpdate: any): Observable<T> {
        return this.http
            .post<T>(this.actionUrl2 + id, JSON.stringify(itemToUpdate));
    }

    public delete(id: number) {
        console.log('Delete Personnel', id);
        return this.http.delete(this.actionUrl2 + id).map((response: Response) => response);
    }
}
