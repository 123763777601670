import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuestionnaireService } from '../services/questionnaire.service';
import { Questionnaire } from '../models';
import { User } from '../models/user';
import * as UserActions from '../store/actions/user-actions';
import { Store } from '@ngrx/store';
import { AppStore } from '../store/app-store';
import { MatDialog } from '@angular/material';
import { DialogClosedDialogComponent } from './dialog-closed.component';
import { DialogThanksDialogComponent } from './dialog-thanks.component';
import { DialogValidateDialogComponent } from './dialog-validate.component';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
    textIntro: string;
    user: User = new User();
    candidat: string;

  constructor(
      public questionnaireService: QuestionnaireService,
      private route: ActivatedRoute,
      private router: Router,
      private store: Store<AppStore>,
      public dialog: MatDialog
  ) {}

  ngOnInit() {
      this.route.params.subscribe((params: any) => {
          if (params.key) {
              const key = this.route.snapshot.paramMap.get('key');
              this.questionnaireService.getSingle(key).subscribe( (x: Questionnaire) => {
                  if (x.candidatId === x.evaluateurId) {
                      this.textIntro = 'Vous pouvez commencer votre auto-évaluation';
                  } else {
                      this.textIntro = 'Vous êtes invité à évaluer <span class="nom-candidat">' + x.candidat + '</span>';
                  }

                  this.user.displayName = x.evaluateur;
                  this.user.logo = x.logo;
                  this.store.dispatch(new UserActions.SetDisplayName(this.user) );
                  this.candidat = x.candidat;

                  this.questionnaireService.selectedItem = x;

                  if (x.etat === 'before' || x.etat === 'after' || x.step === 2) {
                      this.openClosedDialog();
                  }
                  console.log('Questionnaire', x);
              });
          }
      });
  }

  commencer() {
      this.questionnaireService.selectedItem.step = 1;
      this.questionnaireService.update(this.questionnaireService.selectedItem).subscribe();
  }

  nextStep() {
      window.scrollTo(0,0);
      this.questionnaireService.update(this.questionnaireService.selectedItem).subscribe();
  }

    openClosedDialog() {
        this.dialog.open(DialogClosedDialogComponent, {disableClose: true});
    }

    openThanksDialog() {
        this.dialog.open(DialogThanksDialogComponent, {disableClose: true});
    }

    openValidateDialog() {
        const dialogRef = this.dialog.open(DialogValidateDialogComponent, {
            data: {
                name: this.candidat
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'validate') {
                console.log('Validate');
                this.questionnaireService.selectedItem.step = 2;
                this.questionnaireService.update(this.questionnaireService.selectedItem).subscribe();
                this.openThanksDialog();
            }
        });
    }

    questionnaireIncomplet() {
      /* Calculer le nombre de réponses utilisateur */
        let nbReponses = 0;

        for (const reponse of this.questionnaireService.selectedItem.reponses) {
            if (reponse.q11.length) {
                nbReponses++;
            }
            if (reponse.q12.length) {
                nbReponses++;
            }
            if (reponse.q21.length) {
                nbReponses++;
            }
            if (reponse.q22.length) {
                nbReponses++;
            }
            if (reponse.q31.length) {
                nbReponses++;
            }
            if (reponse.q32.length) {
                nbReponses++;
            }
            if (reponse.q41.length) {
                nbReponses++;
            }
            if (reponse.q42.length) {
                nbReponses++;
            }
            if (reponse.q51.length) {
                nbReponses++;
            }
            if (reponse.q52.length) {
                nbReponses++;
            }
        }
        return (nbReponses !== this.questionnaireService.selectedItem.nbquestions);
    }
}
