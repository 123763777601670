import { Injectable } from '@angular/core';
import { Configuration } from '../config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Bilan } from '../models';

@Injectable()
export class BilanService {

    private actionUrl: string;
    selectedItem: Bilan = new Bilan();

    constructor(private http: HttpClient, public _configuration: Configuration) {
        this.actionUrl = _configuration.ServerWithApiUrl + 'bilan/';
    }

    public getAll<T>(): Observable<T> {
        return this.http.get<T>(this.actionUrl);
    }

    public getSingle<T>(id: number): Observable<T> {
        return this.http.get<T>(this.actionUrl + id);
    }

    public relance<T>(id: number): Observable<T> {
        return this.http.get<T>(this.actionUrl + 'relance/' + id);
    }

    public add<T>(itemName: Bilan): Observable<T> {
        const toAdd = JSON.stringify(itemName);

        return this.http.put<T>(this.actionUrl, toAdd);
    }

    public update<T>(id: number, itemToUpdate: any): Observable<T> {
        return this.http
            .post<T>(this.actionUrl + id, JSON.stringify(itemToUpdate.verbatim));
    }

    public delete<T>(id: number): Observable<T> {
        return this.http.delete<T>(this.actionUrl + id);
    }

}
