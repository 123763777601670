export class Client {
    id: number;
    title: string;
    adresse1: string;
    adresse2: string;
    cp: string;
    ville: string;
    site: string;
    logo: string;
    rh: string;
    rhtel: string;
    rhmail: string;
    rh2: string;
    rhtel2: string;
    rhmail2: string;
}
