import { Injectable } from '@angular/core';
import { Questionnaire } from '../models';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Configuration } from '../config';
import { Observable } from 'rxjs';

@Injectable()
export class QuestionnaireService {

    private actionUrl: string;
    selectedItem: Questionnaire = new Questionnaire();

    constructor(private http: HttpClient, private _configuration: Configuration) {
        this.actionUrl = _configuration.ServerWithApiUrl + 'questionnaire/';
    }

    public getSingle<T>(id: string): Observable<T> {
        return this.http.get<T>(this.actionUrl + id);
    }

    public update<T>(itemToUpdate: any): Observable<T> {
        console.log('Update Questionnaire', itemToUpdate);
        return this.http
            .post<T>(this.actionUrl + 'update', JSON.stringify(itemToUpdate));
    }
}
