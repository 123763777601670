import * as jQuery from 'jquery';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { APPCONFIG } from './config';
import { LayoutService } from './layout/layout.service';

// 3rd
import 'styles/material2-theme.scss';
import 'styles/bootstrap.scss';
// custom
import 'styles/layout.scss';
import 'styles/theme.scss';
import 'styles/ui.scss';
import 'styles/app.scss';
import { Store } from '@ngrx/store';
import { AppStore } from './store/app-store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [LayoutService],

})
export class AppComponent implements OnInit {
  title = 'app';
  public isLoggedIn: boolean;
  public userDisplayName: string;
  public userEmail: string;
  public AppConfig: any;
  public isAdmin: boolean;
  sub: any;

  constructor(
      private store: Store<AppStore>,
      public authService: AuthService,
      private router: Router,
      private zone: NgZone
  ) {
      this.authService.afAuth.auth.onAuthStateChanged(
          (auth) => {
              if (auth == null) {
                  this.isLoggedIn = false;
                  this.userDisplayName = '';
                  this.userEmail = '';
                  if (this.router.url === '/') {
                      this.zone.run(() => {
                          this.router.navigate(['/home']);
                      });
                  }
              } else {
                  this.isLoggedIn = true;
                  this.userDisplayName = auth.displayName;
                  console.log('Nom :', this.userDisplayName);
                  this.userEmail = auth.email;
                  console.log('Route :', this.router.url);
                  if (!(this.router.url.startsWith('/questionnaire/'))) {
                      this.router.navigate(['/welcome']);
                  }
              }
          }
      );
      this.sub = store.select((s) => s.user).subscribe((user) => {
          console.log('user', user);
      });
  }

  ngOnInit() {
      this.AppConfig = APPCONFIG;

      // Scroll to top on route change
      this.router.events.subscribe((evt) => {
          if (!(evt instanceof NavigationEnd)) {
              return;
          }
          window.scrollTo(0, 0);
      });
  }

  logout() {
      this.authService.signOut();
      this.router.navigate(['/home']);
  }
}
