import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { Store } from '@ngrx/store';
import { AppStore } from './store/app-store';
import { User } from './models/user';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
    user: Observable<User>;
    token: string;

    constructor(
        private store: Store<AppStore>
    ) {
        this.user = this.store.select('user');
        this.user.subscribe( (data) => {
            console.log('DATA HTTP REQUEST', data);
            this.token = data.token;
        } );
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        console.log('intercepted request ... ');

        // Clone the request to add the new header.
        const authReq = req.clone(
            {
                setHeaders: {
                    Authorization: `Bearer ${this.token}`
                }
            }
        );

        console.log('Sending request with new header now ...');

        // send the newly created request
        return next.handle(authReq)
            .catch((error, caught) => {
                if (error instanceof HttpErrorResponse) {
                    console.log('error ', error.status);
                } else {
                    console.log('Error Occurred');
                    console.log(error);
                    // return the error to the method that called it
                    return Observable.throw(error);
                }
                // intercept the respons error and displace it to the console
            }) as any;
    }
}
