import { Component, OnInit } from '@angular/core';
import { Bilan, BilanReponse } from '../../models';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BilanService } from '../../services/bilan.service';

@Component({
  selector: 'app-bilan-detail',
  templateUrl: './bilan-detail.component.html',
  styleUrls: ['./bilan-detail.component.scss']
})
export class BilanDetailComponent implements OnInit {
    item: Bilan;
    nbPasdemarre: number = 0;
    nbPasdemarrePC: number = 0;
    nbCommence: number = 0;
    nbCommencePC: number = 0;
    nbTermine: number = 0;
    nbTerminePC: number = 0;
    nbTotal: number = 0;

    constructor(
        public itemService: BilanService,
        private _location: Location,
        private route: ActivatedRoute,
    ) {
        route.params.subscribe((params: any) => {
            if (params.key) {
                const key = route.snapshot.paramMap.get('key');
                this.item = new Bilan();
                itemService.getSingle(Number(key)).subscribe( (item) => {
                    this.item = item as Bilan;
                    console.log('Bilan', this.item);
                    for (const reponse of this.item.reponses) {
                        console.log('reponse', reponse);
                        this.nbTotal++;
                        switch (reponse.step) {
                            case '0':
                                this.nbPasdemarre++;
                                break;
                            case '1':
                                this.nbCommence++;
                                break;
                            case '2':
                                this.nbTermine++;
                                break;
                        }
                    }
                    if (this.nbTotal > 0) {
                        this.nbPasdemarrePC = Math.round(100 * this.nbPasdemarre / this.nbTotal);
                        this.nbCommencePC = Math.round(100 * this.nbCommence / this.nbTotal);
                        this.nbTerminePC = Math.round(100 * this.nbTermine / this.nbTotal);
                    }
                });
            }
        });
    }

    ngOnInit() {
    }

    goback() {
        this._location.back();
    }

    isCandidate(reponse: BilanReponse) {
        if (reponse.idEvaluateur === Number(this.item.personnel)) {
            return { 'background-color': '#798799' };
        } else {
            return '';
        }
    }

    getColor(step: string) {
        switch (step) {
            case '0':
                return 'red';
            case '1':
                return 'yellow';
            case '2':
                return 'green';
        }
    }
}
