import { AngularFireAuth } from 'angularfire2/auth';

export class User {
    uid: string;
    displayName: string;
    email: string;
    authState: any = null;
    roles: any[];
    role: string;
    societe: string;
    societeId: number;
    logo: string;
    token: string;

    constructor() {
        this.authState = null;
        this.uid = '';
        this.email = '';
        this.displayName = '';
        this.role = '';
        this.societe = '';
        this.societeId = 0;
        this.logo = '/assets/images/1pixel.png';
        this.token = '';
    }
}
