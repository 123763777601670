/* Modules */
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationRef, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
} from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
// Layout
// import { LayoutComponent } from './layout/layout.component';
import { PreloaderDirective } from './layout/preloader.directive';
// Header
// import { AppHeaderComponent } from './header/header.component';
// Sidenav
// import { AppSidenavComponent } from './sidenav/sidenav.component';
import { ToggleOffcanvasNavDirective } from './sidenav/toggle-offcanvas-nav.directive';
import { AutoCloseMobileNavDirective } from './sidenav/auto-close-mobile-nav.directive';
// import { AppSidenavMenuComponent } from './sidenav/sidenav-menu/sidenav-menu.component';
import { AccordionNavDirective } from './sidenav/sidenav-menu/accordion-nav.directive';
import { AppendSubmenuIconDirective } from './sidenav/sidenav-menu/append-submenu-icon.directive';
import { HighlightActiveItemsDirective } from './sidenav/sidenav-menu/highlight-active-items.directive';
// Customizer
import { AppCustomizerComponent } from './layout/customizer/customizer.component';
import { ToggleQuickviewDirective } from './layout/customizer/toggle-quickview.directive';
// Footer
// import { AppFooterComponent } from './footer/footer.component';
// Search Overaly
import { AppSearchOverlayComponent } from './layout/search-overlay/search-overlay.component';
import { SearchOverlayDirective } from './layout/search-overlay/search-overlay.directive';
import { OpenSearchOverlaylDirective } from './layout/search-overlay/open-search-overlay.directive';

// Pages
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageLayoutFullscreenComponent } from './page-layouts/fullscreen/fullscreen.component';

// Sub modules
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';

/* Environment */
import { environment } from '../environments/environment';

/* Guards */
import { AlwaysAuthGuard } from './guards/alwaysauthguard';

/* Components & providers */
import { AppComponent } from './app.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { AuthService } from './services/auth.service';
import { CompetencesListComponent } from './competences-list/competences-list.component';
import { UserLoginComponent } from './users/user-login/user-login.component';
import { UserProfileComponent } from './users/user-profile/user-profile.component';
import { ClientsModule } from './clients/clients.module';
import { LostPasswordComponent } from './lost-password/lost-password.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { StoreModule } from '@ngrx/store';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Configuration } from './config';
import { BilansModule } from './bilans/bilans.module';
import { QuestionnaireModule } from './questionnaire/questionnaire.module';
import { MyHttpInterceptor } from './my-http-interceptor';
import { Page404Component } from './extra-pages/404/404.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { userReducer } from './store/reducers';
import { WelcomeComponent } from './welcome/welcome.component';
import { DialogConfirmerelanceDialogComponent } from './dashboard/dialog-confirmrelance.component';
import { AideComponent } from './aide/aide.component';
import { DialogConfirmdeleteDialogComponent } from './dashboard/dialog-confirmdelete.component';
import { DialogValidateProfilDialogComponent } from './profils/profil-add/dialog-validate.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    HomePageComponent,
    CompetencesListComponent,
    UserLoginComponent,
    UserProfileComponent,
    // Layout
    // LayoutComponent,
    PreloaderDirective,
    // Header
    // AppHeaderComponent,
    // Sidenav
    // AppSidenavComponent,
    ToggleOffcanvasNavDirective,
    AutoCloseMobileNavDirective,
    // AppSidenavMenuComponent,
    AccordionNavDirective,
    AppendSubmenuIconDirective,
    HighlightActiveItemsDirective,
    // Customizer
    AppCustomizerComponent,
    ToggleQuickviewDirective,
    // Search overlay
    AppSearchOverlayComponent,
    SearchOverlayDirective,
    OpenSearchOverlaylDirective,
    //
    DashboardComponent,
    DialogConfirmerelanceDialogComponent,
    DialogConfirmdeleteDialogComponent,
    // Pages
    PageLayoutFullscreenComponent,
    LostPasswordComponent,
    ConfirmEmailComponent,
    Page404Component,
    WelcomeComponent,
    AideComponent,
    DialogValidateProfilDialogComponent
  ],
  entryComponents: [
    DialogConfirmerelanceDialogComponent,
    DialogConfirmdeleteDialogComponent,
    DialogValidateProfilDialogComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AppRoutingModule,
      HttpClientModule,
      StoreModule.forRoot({ user: userReducer }),
      StoreDevtoolsModule.instrument({
          maxAge: 10 // number of states to retain
      }),
      // Sub modules
    LayoutModule,
    SharedModule,
    ClientsModule,
    BilansModule,
    QuestionnaireModule,
  ],
  providers: [
      {provide: LOCALE_ID, useValue: 'fr'},
    AuthService,
    AlwaysAuthGuard,
      Configuration,
      {
          provide: HTTP_INTERCEPTORS,
          useClass: MyHttpInterceptor,
          multi: true
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor(public appRef: ApplicationRef) {}
}
