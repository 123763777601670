import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ClientService } from '../../services/client.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

    constructor(
        public clientService: ClientService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {
        this.resetForm();
        this.route.params.subscribe((params: any) => {
            if (params.key) {
                const key = this.route.snapshot.paramMap.get('key');
            }
        });
    }

    onSubmit(form: NgForm) {
        if (form.value.id == null) {
            this.clientService.add(this.clientService.selectedClient).subscribe( (x) => { this.router.navigateByUrl('/clients/list'); } );
        } else {
            this.clientService.update(this.clientService.selectedClient.id, this.clientService.selectedClient).subscribe( (x) => { this.router.navigateByUrl('/clients/list'); } );
        }
    }

    resetForm(form?: NgForm) {
        if (form != null) {
            form.reset();
        }
    }

    onDelete(form: NgForm ) {
        if (confirm('Are you sure to delete this record ?') === true) {
            // this.clientService.deleteClient(form.value.$key);
            this.router.navigateByUrl('/clients/list');
        }
    }

}
