import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LayoutRoutingModule } from './layout-routing.module';
import { SharedModule } from '../shared/shared.module';
import { AppHeaderComponent } from '../header/header.component';
import { LayoutComponent } from './layout.component';
import { AppSidenavComponent } from '../sidenav/sidenav.component';
import { AppFooterComponent } from '../footer/footer.component';
import { AppSidenavMenuComponent } from '../sidenav/sidenav-menu/sidenav-menu.component';
import { MatButtonModule } from '@angular/material';

@NgModule({
    imports: [
        CommonModule,
        LayoutRoutingModule,
        SharedModule,
        MatButtonModule
    ],
    declarations: [
        AppHeaderComponent,
        LayoutComponent,
        AppSidenavComponent,
        AppFooterComponent,
        AppSidenavMenuComponent
    ],
    exports: [
        AppHeaderComponent,
        LayoutComponent,
        AppSidenavComponent,
        AppFooterComponent,
        AppSidenavMenuComponent
    ]
})

export class LayoutModule {}
