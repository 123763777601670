import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppStore } from '../store/app-store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
    signupForm: FormGroup;
    // detailForm: FormGroup;
    erreur = '';

    constructor(
        public fb: FormBuilder,
        public auth: AuthService,
        private router: Router,
        private store: Store<AppStore>,
        ) { }
    ngOnInit() {
        // // First Step
        this.signupForm = this.fb.group({
            email: ['', [
                Validators.required,
                Validators.email
            ]
            ],
            password: ['', [
            //     Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
            //     Validators.minLength(6),
            //     Validators.maxLength(25),
                Validators.required
            ]
            ],
        });
    }

    get email() { return this.signupForm.get('email'); }

    get password() { return this.signupForm.get('password'); }

    signup() {
        return this.auth.emailSignUp(this.email.value, this.password.value);
    }

    emailLogin() {
        console.log('Email login');
        this.auth.emailLogin(this.email.value, this.password.value).then((data) => {
            this.router.navigate(['/app/dashboard']);
        }, (data) => {
          console.log('Erreur, ne pas se connecter...', data);
          if (data.code === 'auth/wrong-password') {
            this.erreur = 'Mot de passe incorrect';
          } else {
            if (data.code === 'auth/user-not-found') {
              this.erreur = 'Utilisateur inconnu';
            } else {
              this.erreur = 'Erreur d\'identification';
            }
          }
        });
    }
    googleLogin() {
        this.auth.googleLogin().then((data) => {
            this.router.navigate(['/app/dashboard']);
        });
    }
    facebookLogin() {
        this.auth.facebookLogin().then((data) => {
            this.router.navigate(['/app/dashboard']);
        });
    }
    twitterLogin() {
        this.auth.twitterLogin().then((data) => {
            this.router.navigate(['/app/dashboard']);
        });
    }
}
