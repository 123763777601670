import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientsRoutingModule } from './clients-routing.module';
import { ClientComponent } from './client/client.component';
import { ClientsComponent } from './clients.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientService } from '../services/client.service';
import { FormsModule } from '@angular/forms';
import { MatButtonModule, MatInputModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    ClientsRoutingModule,
      FormsModule,
      MatInputModule,
      MatButtonModule
  ],
  declarations: [
      ClientComponent,
      ClientsComponent,
      ClientListComponent
  ],
  providers: [
      ClientService
  ]
})
export class ClientsModule { }
