import { Component, OnInit } from '@angular/core';
import { APPCONFIG } from '../config';
import { AuthService } from '../services/auth.service';
import { AppStore } from '../store/app-store';
import { Store } from '@ngrx/store';
import { User } from '../models/user';
import { Observable } from 'rxjs';

@Component({
  selector: 'my-app-header',
  styles: [],
  templateUrl: './header.component.html'
})

export class AppHeaderComponent implements OnInit {
  public AppConfig: any;
  displayName: any;
  user: Observable<User>;

  constructor(
    public authService: AuthService,
    private store: Store<AppStore>
  ) {
      this.user = this.store.select('user');
  }

  ngOnInit() {
    this.AppConfig = APPCONFIG;

    if (this.authService.authenticated) {
        this.authService.getCurrentUserDisplayName().subscribe( (data) => this.displayName = data.displayName );
    }
  }

  logout() {
      this.authService.signOut();
  }

  isLoggedIn() {
    return this.authService.authenticated;
  }
}
