import * as UserActions from '../actions/user-actions';
import { User } from '../../models/user';

export type Action = UserActions.All;

/// Default app state
const defaultState = new User();

/// Helper function to create new state object
const newState = (state, newData) => {
    return Object.assign({}, state, newData);
}

export function userReducer(state: User = defaultState, action: Action) {
    console.log(action.type, state);
    console.log(action.type, action.payload);

    switch (action.type) {
        case UserActions.LOGIN_SUCCESS:
            return newState(state, {
                displayName: action.payload.displayName,
                role: action.payload.role,
                societe: action.payload.societe,
                societeId: action.payload.societeId,
                logo: action.payload.logo,
                uid: action.payload.uid,
                email: action.payload.email,
                token: action.payload.token
            });
        case UserActions.SET_DISPLAY_NAME:
            return newState(state, {
                displayName: action.payload.displayName,
                logo: action.payload.logo
            });
        case UserActions.LOGOFF:
            return newState(state, {
                displayName: '',
                role: '',
                societe: '',
                societeId: 0,
                uid: '',
                email: '',
                logo: '/assets/images/1pixel.png',
                token: ''
            });
        default:
            return state;
    }
};
