import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

// openDialogBefore
@Component({
    selector: 'dialog-closed-dialog',
    template: `
        <i class="material-icons dialog-grande-icone">sentiment_dissatisfied</i>
        <div md-dialog-content class="dialog-grand-texte">Cette évaluation est cloturée</div>
    `,
})
export class DialogClosedDialogComponent {
    constructor(public dialogRef: MatDialogRef<DialogClosedDialogComponent>) {}
}
