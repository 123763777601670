import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layout.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { CompetencesListComponent } from '../competences-list/competences-list.component';
import { AideComponent } from '../aide/aide.component';
import {
  AlwaysAuthGuard as AuthGuard
} from '../guards/alwaysauthguard';

const routes: Routes = [
  {
    path: 'app',
    canActivate: [AuthGuard],
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: '/app/dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'personnels', loadChildren: '../personnels/personnels.module#PersonnelsModule' },
      { path: 'profils', loadChildren: '../profils/profils.module#ProfilsModule' },
      { path: 'bilans', loadChildren: '../bilans/bilans.module#BilansModule' },
      { path: 'competences', component: CompetencesListComponent },
      { path: 'aide', component: AideComponent },
    ]
  }
];

export const LayoutRoutingModule = RouterModule.forChild(routes);
